var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.previewVisible
        ? _c(
            "a-modal",
            {
              attrs: { visible: _vm.previewVisible, footer: null },
              on: { cancel: _vm.handleCancelPreview }
            },
            [
              _c(
                "div",
                { staticClass: "review-wrap" },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "lazy",
                        rawName: "v-lazy",
                        value: _vm.urlData,
                        expression: "urlData"
                      }
                    ],
                    staticClass: "mb-sm",
                    style: _vm.cssProps,
                    attrs: { alt: "preview" }
                  }),
                  _vm.error
                    ? _c(
                        "a-row",
                        { style: { color: "#F5222D", fontSize: "20px" } },
                        [
                          _c("span", { staticClass: "mr-md" }, [
                            _vm._v(_vm._s(_vm.$t("Error")) + ":")
                          ]),
                          _c("span", [_vm._v(_vm._s(_vm.error))])
                        ]
                      )
                    : _c(
                        "a-button",
                        {
                          attrs: { type: "link", icon: "download" },
                          on: { click: _vm.downloadLink }
                        },
                        [_vm._v(_vm._s(_vm.$t("Download")))]
                      )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }