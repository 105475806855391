let container = [];
const getNameSocket = options => {
  let collectionName = options.collectionName;
  let modelId = options.modelId;
  let method = options.method;
  let name = '';
  if (method === 'POST') {
    name = '/' + collectionName + '/' + method;
  } else {
    name = '/' + collectionName + '/' + modelId + '/' + method;
  }
  return name;
};
const subscribe = (sockets, options, callback) => {
  if (options) {
    let name = getNameSocket(options);
    sockets.subscribe(name, callback);
    //Push the container..
    pushContainer(name);
  } else {
    throw 'Error: Option must be an object';
  }
}; //end subscribe

const pushContainer = subscriptionName => {
  container.push(subscriptionName);
};

//Unsubscribe all containers..
const unSubscribeAll = sockets => {
  for (let i = 0; i < container.length; i++) {
    sockets.unsubscribe(container[i]);
  }
  //Now reset the container..
  container = [];
};

//Unsubscribe specific container..
const unSubscribe = (sockets, options) => {
  let name = getNameSocket(options);
  for (let i = 0; i < container.length; i++) {
    if (container[i] === name) {
      sockets.unsubscribe(container[i]);
      container.splice(i, 1);
      break;
    }
  }
};
export { subscribe, pushContainer, unSubscribeAll, unSubscribe };
