//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import * as pubsub from '@/pubsub';

export default {
  data() {
    return {
      previewVisible: false,
      urlData: '/img/loading-img.gif',
      error: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters('user', ['token']),
    cssProps() {
      return {
        width: '100%',
        height: '380px',
        'object-fit': 'contain'
      };
    }
  },
  methods: {
    async handlePreview(data) {
      this.loading = true;
      this.previewVisible = true;
      fetch(`${process.env.VUE_APP_API_BASE_URL}/hub/preview-code?access_token=${this.token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(async response => {
        if (response.status === 200) {
          // response.blob().then(async (myBlob) => {
          //   const objectURL =  await URL.createObjectURL(myBlob);
          //   this.urlData = objectURL;
          // });
        } else {
          this.previewVisible = false;
          const error = await response.json();
          this.$notification.error({
            message: this.$t(error?.error_code || 'invalidData')
          });
        }
      });
      const subscribePayload = {
        collectionName: 'PREVIEW_CODE',
        method: 'POST'
      };
      pubsub.subscribe(this.sockets, subscribePayload, previewCode => {
        this.urlData = previewCode.url;
        this.error = previewCode.error;
        pubsub.unSubscribeAll(this.sockets);
      });
    },
    handleCancelPreview() {
      this.urlData = '/img/loading-img.gif';
      this.previewVisible = false;
      this.error = null;
    },
    downloadLink() {
      // const timestamp = new Date().getTime();
      const a = document.createElement('a'); //Create <a>
      a.href = `${this.urlData}`; //Image Base64 Goes here
      a.download = 'Image.png'; //File name Here
      a.click(); //Downloaded file
    }
  }
};
